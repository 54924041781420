import React from "react";
import Navbar from "components/Navbar/navbar";
import FullTestimonials from "components/Full-testimonials/full-testimonials";
import CallToAction from "components/Call-to-action/call-to-action";
import Footer from "components/Footer/footer";
import Works3 from "components/Works3/works3";
import Blogs4 from "components/blogs/Blogs4/blogs4";
import Intro4 from "components/Intro4/intro4";
import AboutUs from "components/About-us/about-us";
import Services from "components/Services/services";
import MinimalArea2 from "components/Minimal-Area2/minimal-area2";
import DarkTheme from "layouts/Dark";
import Video2 from "components/Video2/video2";

const Homepage = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.scrollY > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <Intro4 />
      <AboutUs />
      {/* <Works3 /> */}
      <Video2 />
      <Services />
      {/* <MinimalArea2 /> */}
      {/* <FullTestimonials showHead /> */}
      <Blogs4 />
      <CallToAction />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>AcmeWays</title>
    </>
  )
}

export default Homepage;
